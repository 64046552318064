import widgetLanguages from './widgetLanguages';
import getWordForm, { WORD_FORMS } from '../../../helpers/getWordForm';

const WT = {
  person: {
    [widgetLanguages.ru.code]: (count) => getWordForm(count, WORD_FORMS.person),
    [widgetLanguages.en.code]: (count) => (count === 1 ? 'person' : 'persons'),
  },
  continue: {
    [widgetLanguages.ru.code]: () => 'Продолжить',
    [widgetLanguages.en.code]: () => 'Continue',
  },
  policy: {
    [widgetLanguages.ru.code]: () => 'Нажимая кнопку “Забронировать” Вы принимаете условия пользовательского соглашения и политики конфиденциальности',
    [widgetLanguages.en.code]: () => 'By clicking the “Book” button you accept the terms of the user agreement and privacy policy',
  },
  loading: {
    [widgetLanguages.ru.code]: () => 'Загрузка...',
    [widgetLanguages.en.code]: () => 'Loading...',
  },
  yourName: {
    [widgetLanguages.ru.code]: () => 'Ваше имя',
    [widgetLanguages.en.code]: () => 'Your name',
  },
  yourNumber: {
    [widgetLanguages.ru.code]: () => 'Ваш телефон',
    [widgetLanguages.en.code]: () => 'Your phone number',
  },
  namePlaceholder: {
    [widgetLanguages.ru.code]: () => 'Иван',
    [widgetLanguages.en.code]: () => 'John',
  },
  bookingRequests: {
    [widgetLanguages.ru.code]: () => 'Пожелания к бронированию',
    [widgetLanguages.en.code]: () => 'Booking requests',
  },
  book: {
    [widgetLanguages.ru.code]: () => 'Забронировать',
    [widgetLanguages.en.code]: () => 'Book',
  },
  success: {
    [widgetLanguages.ru.code]: () => 'Успешно',
    [widgetLanguages.en.code]: () => 'Success',
  },
  successfullyCreated: {
    [widgetLanguages.ru.code]: () => 'Ваша бронь была успешно создана',
    [widgetLanguages.en.code]: () => 'Your book was successfully created',
  },
  sure: {
    [widgetLanguages.ru.code]: () => 'Уверены?',
    [widgetLanguages.en.code]: () => 'Sure?',
  },
  sureText: {
    [widgetLanguages.ru.code]: () => 'Вы уверены, что хотите закрыть окно бронирования? Внесённые данные будут утеряны',
    [widgetLanguages.en.code]: () => 'Are you sure you want to close the booking window? The data you entered will be lost',
  },
};

export default WT;
