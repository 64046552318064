import React, { useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import Draggable from 'react-draggable';
import styles from './css/index.module.scss';
import { TOOlS_BUTTONS } from '../../constants';
import {
  gridRefCurrentAtom,
  gridZoomScaleAtom,
  objectsListAtom,
  selectedObjectIndexAtom,
} from '../../../../atoms/schemeAtoms';
import PlanServices from '../../../../services/PlanServices';
import SimpleObject from '../PlanObjects/ObjectTypes/SimpleObject';
import AnyObject from '../PlanObjects/ObjectTypes/AnyObject';
import { zoomOutRatioAtom } from '../../../../atoms/globalAtoms';

function PlanObjectsTools() {
  const [objectsList, setObjectsList] = useAtom(objectsListAtom);
  const setSelectedObjectIndex = useSetAtom(selectedObjectIndexAtom);
  const gridZoomScale = useAtomValue(gridZoomScaleAtom);
  const gridRefCurrent = useAtomValue(gridRefCurrentAtom);
  const [zoomOutRatio] = useAtom(zoomOutRatioAtom);

  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [draggablePosition, setDraggablePosition] = useState({ x: 0, y: 0 });

  const onDragStart = (index) => (ev, data) => {
    setSelectedButtonIndex(index);

    const componentRect = data.node.getBoundingClientRect();

    const offsetX = (ev.clientX - componentRect.left - componentRect.width / 2) / zoomOutRatio;
    const offsetY = (ev.clientY - componentRect.top - componentRect.height / 2) / zoomOutRatio;

    setDraggablePosition({ x: offsetX, y: offsetY });
  };

  const onDrag = () => (ev, data) => {
    // considering only window zoom
    const diffX = data.x - draggablePosition.x;
    const changeX = (diffX) / zoomOutRatio;
    const diffY = data.y - draggablePosition.y;
    const changeY = (diffY) / zoomOutRatio;

    setDraggablePosition({ x: draggablePosition.x + changeX, y: draggablePosition.y + changeY });
  };

  const onDragEnd = (index) => (ev, data) => {
    setSelectedButtonIndex(null);
    setDraggablePosition({ x: 0, y: 0 });

    if ((data.x / gridZoomScale) >= 150 && gridRefCurrent) {
      const rect = gridRefCurrent.getBoundingClientRect();

      const x = (ev.clientX - rect.left) / (gridZoomScale * zoomOutRatio);
      const y = (ev.clientY - rect.top) / (gridZoomScale * zoomOutRatio);
      const newObject = PlanServices.generateNewObject(TOOlS_BUTTONS[index].object, x, y, objectsList);

      setObjectsList((prev) => [...prev, newObject]);
      setSelectedObjectIndex(-1);
    }
  };

  return (
    <div className={styles.list}>
      {TOOlS_BUTTONS.map((button, index) => (
        button.text ? (
          <div
            key={button.text}
            className={styles.button}
          >
            <Draggable
              position={selectedButtonIndex === index ? draggablePosition : { x: 0, y: 0 }}
              onStart={onDragStart(index)}
              onStop={onDragEnd(index)}
              onDrag={onDrag(index)}
            >
              {selectedButtonIndex === index ? (
                <div className={styles.buttonDraggableDragging}>
                  <div style={{ transform: `scale(${gridZoomScale})` }}>
                    {button.object.type === 'anything' ? (
                      <AnyObject object={{ ...button.object, fields: button.object.defaultFields }} />
                    ) : (
                      <SimpleObject object={{ ...button.object, fields: button.object.defaultFields }} />
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.buttonDraggable}>
                  <img
                    draggable={false}
                    src={button.svg}
                    alt=""
                  />
                </div>
              )}

            </Draggable>

            <img
              src={button.svg}
              alt=""
              draggable={false}
            />

            <p className={styles.buttonText}>{button.text}</p>
          </div>

        ) : <div style={{ width: '100px' }} key={index} />
      ))}
    </div>
  );
}

export default PlanObjectsTools;
