import React from 'react';
import { useAtom } from 'jotai/index';
import styles from '../css/index.module.scss';
import { zoomOutRatioAtom } from '../../../atoms/globalAtoms';

function ReservesChartWrapper({
  width,
  height,
  title,
  headerPadding,
  children,
  points,
  considerZoom = true,
}) {
  const [zoomOutRatio] = useAtom(zoomOutRatioAtom);

  return (
    <div className={styles.chartsItem} style={{ width: `${width}px`, height: `${height}px` }}>
      <div className={styles.chartsItemHeader} style={{ paddingLeft: `${headerPadding}px` }}>
        <p className={styles.chartsItemTitle}>{title}</p>
        <div className={styles.chartsItemPoints}>
          {points.map((item) => (
            <div className={styles.chartsItemPoint} key={item.name}>
              <div className={styles.chartsItemPointColor} style={{ backgroundColor: item.color }} />
              <p className={styles.chartsItemPointText}>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div
        className={styles.chartsItemChart}
        style={{
          width: considerZoom ? `${100 / zoomOutRatio}%` : '100%',
          height: considerZoom ? `calc(${100 / zoomOutRatio}% - ${(1 / zoomOutRatio) * 73}px)` : 'calc(100% - 73px)',
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default ReservesChartWrapper;
