import React, { useRef, useState } from 'react';
import './css/index.scss';
import TooltipIcon from '../../assets/formIcons/tooltip.svg';

function IconButton({
  svgPath,
  iconClassName = '',
  wrapperClassName = '',
  loading,
  onClick,
  isPng,
  tooltipText,
  withEvent,
  noIconFilter,
}) {
  const hoverTimer = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleClick = (ev) => {
    if (loading) return;

    if (withEvent) onClick(ev);
    else onClick();
  };

  const onHoverIn = () => {
    if (!tooltipText) return;
    hoverTimer.current = setTimeout(() => {
      setIsTooltipVisible(true);
    }, 1000);
  };

  const onHoverOut = () => {
    clearTimeout(hoverTimer.current);
    if (isTooltipVisible) {
      setIsTooltipVisible(false);
    }
  };

  return (
    <div
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      className={`icon_button_wrapper ${loading && 'disabled'} ${wrapperClassName}`}
      onClick={handleClick}
    >
      {svgPath && (
        <img
          src={svgPath}
          alt=""
          className={`${iconClassName} ${isPng && 'is_png'} ${noIconFilter && 'no_filter'} icon_button_inner`}
        />
      )}

      <div className={`tooltip_wrapper ${isTooltipVisible && 'visible'}`}>
        <img src={TooltipIcon} alt="" className="tooltip_img" />
        <p className="tooltip_text">{tooltipText}</p>
      </div>
    </div>
  );
}

export default IconButton;
