import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import styles from './css/index.module.scss';
import PlanServices from '../../../../services/PlanServices';
import { gridZoomScaleAtom, schemeDotsAtom } from '../../../../atoms/schemeAtoms';
import { zoomOutRatioAtom } from '../../../../atoms/globalAtoms';

function LineDot({ dot1Index, dot2Index }) {
  const [schemeDots, setSchemeDots] = useAtom(schemeDotsAtom);
  const [gridZoomScale, setGridZoomScale] = useAtom(gridZoomScaleAtom);
  const [zoomOutRatio] = useAtom(zoomOutRatioAtom);

  const [dot1, dot2] = [schemeDots[dot1Index], schemeDots[dot2Index]];
  const [currentPosition, setCurrentPosition] = useState([0, 0]);

  useEffect(() => {
    setCurrentPosition([(dot1[0] + dot2[0]) / 2, (dot1[1] + dot2[1]) / 2]);
  }, [dot1, dot2]);

  const onDrag = (_, data) => {
    const newDots = [...schemeDots];

    // considering grid scale and window zoom
    const diffX = data.x - currentPosition[0];
    const changeX = (diffX - (gridZoomScale * zoomOutRatio) * diffX) / (gridZoomScale * zoomOutRatio);
    const diffY = data.y - currentPosition[1];
    const changeY = (diffY - (gridZoomScale * zoomOutRatio) * diffY) / (gridZoomScale * zoomOutRatio);

    const positionChanges = [(data.x + changeX) - currentPosition[0], (data.y + changeY) - currentPosition[1]];

    newDots[dot1Index] = [newDots[dot1Index][0] + positionChanges[0], newDots[dot1Index][1] + positionChanges[1], newDots[dot1Index][2]];
    newDots[dot2Index] = [newDots[dot2Index][0] + positionChanges[0], newDots[dot2Index][1] + positionChanges[1], newDots[dot2Index][2]];
    setSchemeDots(newDots);
  };

  return (
    <Draggable
      onStart={(ev) => ev.stopPropagation()}
      onDrag={onDrag}
      position={{ x: currentPosition[0], y: currentPosition[1] }}
    >
      <div className={styles.lineDot}>
        <div
          className={styles.lineDotInner}
          style={{
            width: `${PlanServices.lineDotSize}px`,
            height: `${PlanServices.lineDotSize}px`,
          }}
        />
      </div>
    </Draggable>
  );
}

function SchemeLineDots() {
  const [schemeDots, setSchemeDots] = useAtom(schemeDotsAtom);

  return (
    schemeDots.map((_, index) => {
      const nextIndex = index === schemeDots.length - 1 ? 0 : index + 1;

      return (
        <LineDot key={schemeDots[index][2]} dot1Index={index} dot2Index={nextIndex} />
      );
    })
  );
}

export default SchemeLineDots;
