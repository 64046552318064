import React, { useMemo } from 'react';
import { useAtom } from 'jotai/index';
import classNames from 'classnames';
import styles from '../css/index.module.scss';
import {
  widgetDataAtom, widgetGettingLoadingAtom,
  widgetInputsAtom,
  widgetParamsAtom, widgetScreenStateAtom,
  widgetSelectedLanguageAtom,
} from '../../../atoms/widgetAtoms';
import useWidget from '../../../hooks/UseWidget';
import WT from '../constants/widgetTranslations';
import EmptyHeight from '../../../components/EmptyHeight';
import IconButton from '../../../components/IconButton';
import WidgetMinusIcon from './WidgetMinusIcon';
import WidgetPlusIcon from './WidgetPlusIcon';
import WidgetDateInput from './WidgetDateInput';
import PartnersServices from '../../../services/PartnersServices';
import getFilteredWidgetSlots from '../../../helpers/getFilteredWidgetSlots';
import MyButton from '../../../components/MyButton';
import widgetStates from '../constants/widgetStates';

function WidgetSlotsState() {
  const { t } = useWidget();
  const [widgetParams, setWidgetParams] = useAtom(widgetParamsAtom);
  const [widgetData] = useAtom(widgetDataAtom);
  const [widgetSelectedLanguage] = useAtom(widgetSelectedLanguageAtom);
  const [widgetInputs, setWidgetInputs] = useAtom(widgetInputsAtom);
  const [widgetGettingLoading] = useAtom(widgetGettingLoadingAtom);
  const [, setWidgetScreenState] = useAtom(widgetScreenStateAtom);

  const filteredSlots = useMemo(() => getFilteredWidgetSlots(widgetData.slots, widgetParams), [widgetData.slots]);

  return (
    <>
      <div className={styles.slotsState}>
        <div className={styles.row}>
          <p className={styles.text}>
            {widgetParams.persons}
            {' '}
            {t(WT.person, widgetParams.persons)}
          </p>
          <div className={styles.buttons}>
            <IconButton
              onClick={() => setWidgetParams({ persons: Math.max(widgetParams.persons - 1, 1) })}
              svgPath={WidgetMinusIcon()}
              wrapperClassName={styles.button}
              noIconFilter
            />
            <IconButton
              onClick={() => setWidgetParams({ persons: Math.max(widgetParams.persons + 1, 1) })}
              svgPath={WidgetPlusIcon()}
              wrapperClassName={styles.button}
              noIconFilter
            />
          </div>
        </div>
        <EmptyHeight height={24} />
        <div className={styles.row}>
          <WidgetDateInput
            value={widgetParams.date}
            onChange={(val) => setWidgetParams({ date: val })}
            locale={widgetSelectedLanguage.code}
          />
        </div>
        <EmptyHeight height={24} />

        <div className={styles.mainPart}>
          {typeof widgetData.max_guests === 'number' && widgetData.max_guests < widgetParams.persons ? (
            <p className={styles.mediumText}>
              Ваше бронирование требует большого внимания! Пожалуйста, позвоните в ресторан по номеру:
              {' '}
              <a
                href={`tel:${'+7 999 123 34 55'}`}
                className={classNames(styles.phoneNumber, styles.clickableOpacity)}
              >
                +7 999 123 34 55
              </a>
            </p>
          ) : widgetGettingLoading
            ? <p className={styles.text}>{t(WT.loading)}</p>
            : (
              <div className={styles.slotsRow}>
                {filteredSlots.map((slot) => (
                  <div
                    key={slot}
                    onClick={() => setWidgetInputs({ slot })}
                    className={classNames(styles.slot, styles.clickable, { [styles.slotSelected]: widgetInputs.slot === slot })}
                  >
                    <p className={styles.text}>{PartnersServices.convertSecondsToFormat(slot)}</p>
                  </div>
                ))}
              </div>
            )}
        </div>
      </div>
      <div>
        <MyButton
          text={t(WT.continue)}
          className={classNames(styles.myButton, { [styles.myButtonDisabled]: !widgetInputs.slot })}
          disabled={!widgetInputs.slot}
          onClick={() => widgetData.max_guests >= widgetParams.persons && setWidgetScreenState(widgetStates.inputs)}
        />
        <EmptyHeight height={16} />
        <p className={styles.policyText}>{t(WT.policy)}</p>
      </div>
    </>
  );
}

export default WidgetSlotsState;
