import React, { useEffect, useRef } from 'react';
import './css/index.scss';
import { useAtomValue, useSetAtom, useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import Sidebar from '../SideBar';
import {
  containerRefCurrentAtom, isNavBarExpandedAtom, isSmallWindowAtom, windowHeightAtom, zoomOutRatioAtom,
} from '../../atoms/globalAtoms';
import MyModal from '../MyModal';
import useWindowSize from '../../hooks/UseWindowResize';

function Wrapper({ children }) {
  const [width, height] = useWindowSize();
  const containerRefCurrent = useRef(null);
  const setContainerRefCurrent = useSetAtom(containerRefCurrentAtom);
  const isSmallWindow = useAtomValue(isSmallWindowAtom);
  const [isNavBarExpandedWindow] = useAtom(isNavBarExpandedAtom);
  const [, setZoomOutRatio] = useAtom(zoomOutRatioAtom);
  const [, setWindowHeight] = useAtom(windowHeightAtom);
  const location = useLocation();

  function zoom(scale) {
    const container = document.getElementById('zoom-container');
    const content = document.getElementById('zoom-content');

    if (!container || !content) return;
    content.style.transform = `scale(${scale})`;
    content.style.height = `${100 / scale}vh`;
  }

  useEffect(() => {
    if (!containerRefCurrent.current) return;

    setContainerRefCurrent(containerRefCurrent.current);
  }, [containerRefCurrent.current]);

  useEffect(() => {
    const content = document.getElementById('zoom-content');
    // change 1800 in css too
    const ratio = Math.min(1, Math.max(0.7, width / 1800));
    if (true) {
      zoom(ratio);
      setZoomOutRatio(ratio);
      if (content) {
        content.style.minWidth = '1800px';
      }
    } else {
      zoom(1);
      setZoomOutRatio(1);
      if (content) {
        content.style.minWidth = '100px';
      }
    }
  }, [width, location.pathname]);

  useEffect(() => {
    setWindowHeight(height);
  }, [height]);

  return (
    <div id="zoom-container">
      <div id="zoom-content">

        <div className="wrapper">
          <Sidebar />
          <div id="container" className={`container ${(isSmallWindow || !isNavBarExpandedWindow) && 'small'}`} ref={containerRefCurrent}>
            <div className="container_inner">
              {children}
            </div>
          </div>

          <MyModal />
        </div>
      </div>
    </div>
  );
}

export default Wrapper;
