import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useAtom } from 'jotai/index';
import styles from './css/index.module.scss';
import TableSortGrayUp from '../../assets/icons/tableSortGrayUp.svg';
import TableSortBlueUp from '../../assets/icons/tableSortBlueUp.svg';
import TablePagination from '../TablePagination';
import InfoComponent from './components/InfoComponent';
import { isRootAtom, windowHeightAtom, zoomOutRatioAtom } from '../../atoms/globalAtoms';

function MyTable({
  data = [],
  headers = [],
  renderComponent,
  filtersAtom,
  heightToMinus,
  withPagination = true,
  totalPages = 1,
  onRowClick,
}) {
  const [isRoot] = useAtom(isRootAtom);
  const [zoomOutRatio] = useAtom(zoomOutRatioAtom);
  const [windowHeight] = useAtom(windowHeightAtom);

  if (withPagination) heightToMinus += 96;
  const filteredHeaders = useMemo(() => headers.filter((i) => isRoot || !i.forRootOnly), [isRoot, headers]);
  const [filters, setFilters] = useAtom(filtersAtom);

  const onSortChange = (header) => () => {
    if (!header.withSort) return;

    if (filters.sortKey === header.key) {
      if (filters.sortType === 'asc') filters.sortType = 'desc';
      else if (filters.sortType === 'desc') {
        filters.sortKey = '';
        filters.sortType = '';
      }
    } else {
      filters.sortKey = header.key;
      filters.sortType = 'asc';
    }
    filters.page = 1;

    setFilters({ ...filters });
  };

  const onPageChange = (newPage) => {
    setFilters({ page: newPage });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableContainer} style={{ minHeight: `${(windowHeight - heightToMinus) / zoomOutRatio}px` }}>
        <table className={styles.table}>

          <thead className={styles.thead}>
            <tr className={styles.headerRow}>
              {filteredHeaders.map((header) => (
                <th
                  key={header.key}
                  className={styles.headerCell}
                  style={{ width: header.width }}
                >
                  <div
                    onClick={onSortChange(header)}
                    className={classNames(styles.headerCellInner, { [styles.headerCellSortable]: header.withSort })}
                  >
                    <div className={styles.headerCellLeft}>
                      {header.infoComponent && <InfoComponent component={header.infoComponent} />}
                      {header.text}
                    </div>
                    {header.withSort && (
                    <div className={styles.sortArrows}>
                      <img
                        src={(filters.sortKey === header.key && filters.sortType === 'asc') ? TableSortBlueUp : TableSortGrayUp}
                        alt="^"
                        className={classNames(styles.arrow)}
                      />
                      <img
                        src={(filters.sortKey === header.key && filters.sortType === 'desc') ? TableSortBlueUp : TableSortGrayUp}
                        alt="V"
                        className={classNames(styles.arrow, styles.arrowDown)}
                      />
                    </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => (
              <tr
                key={item.id}
                className={classNames(styles.itemsRow, { [styles.itemsRowClickable]: typeof onRowClick === 'function' })}
                onClick={onRowClick ? (ev) => onRowClick(ev, item) : undefined}
              >
                {filteredHeaders.map((header, headerIndex) => (
                  <td
                    key={header.key}
                    className={styles.itemCell}
                    style={{ width: header.width }}
                  >
                    {renderComponent({
                      item, header, index, headerIndex,
                    })}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

        </table>
      </div>

      <TablePagination
        page={filters.page || 1}
        setPage={onPageChange}
        pagesCount={totalPages}
      />
    </div>
  );
}

export default MyTable;
