import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai/index';
import { reservesDropdownSetterAtom, reservesInfoAtom, reservesTableFiltersAtom } from '../../../atoms/reservesAtoms';
import styles from '../css/index.module.scss';
import MyButton from '../../../components/MyButton';
import Accordion from '../../../components/Accordion';
import MyCheckbox from '../../../components/MyCheckbox';
import MyInput from '../../../components/MyInput';
import deepClone from '../../../helpers/deepClone';
import { isRootAtom } from '../../../atoms/globalAtoms';
import sendRequest from '../../../helpers/sendRequest';
import Api from '../../../api';

function ReservesTableFilters({ isComponentVisible }) {
  const [isRoot] = useAtom(isRootAtom);
  const [reservesTableFilters, setReservesTableFilters] = useAtom(reservesTableFiltersAtom);
  const [reservesDropdownSetter] = useAtom(reservesDropdownSetterAtom);
  const [reservesInfo, setReservesInfo] = useAtom(reservesInfoAtom);
  const [checkBoxInputs, setCheckBoxInputs] = useState({});
  const [filters, setFilters] = useState(deepClone(reservesTableFilters));

  const onSaveClick = () => {
    reservesDropdownSetter(false);
    setReservesTableFilters(deepClone(filters));
  };

  const onCheckBoxClick = (key, item) => {
    const currentList = filters[key] || [];
    if (currentList.includes(item.id)) {
      setFilters((prev) => ({ ...prev, [key]: currentList.filter((el) => el !== item.id) }));
    } else {
      setFilters((prev) => ({ ...prev, [key]: [...currentList, item.id] }));
    }

    // cleaning addresses if partners were changed
    if (key === 'partners') {
      setFilters((prev) => ({ ...prev, addresses: [] }));
    }
  };

  const handleCheckBoxInput = (key, ev) => {
    setCheckBoxInputs((prev) => ({ ...prev, [key]: ev.target.value }));
  };

  const checkIfFiltered = (val1, val2) => val1.toLowerCase().includes(val2.toLowerCase());

  const getAndSetAddressesOfPartners = async (partners) => {
    const data = await sendRequest({
      request: Api.getAddressesOfPartners,
      payload: { company_id: partners },
      warnErrorText: 'while getting addresses of partners',
    });

    if (data?.status !== 'ok') {
      return setReservesInfo((prev) => ({ ...prev, addresses: [] }));
    }

    setReservesInfo((prev) => ({ ...prev, addresses: data.data.addresses }));
  };

  useEffect(() => {
    // getting addresses of these partners
    if (!isRoot) return;
    if (!filters.partners?.length) {
      setReservesInfo((prev) => ({ ...prev, addresses: [] }));
    } else {
      getAndSetAddressesOfPartners(filters.partners).then();
    }
  }, [filters.partners]);

  useEffect(() => {
    setFilters(deepClone(reservesTableFilters));
  }, [reservesTableFilters]);

  useEffect(() => {
    if (!isComponentVisible) {
      setFilters(deepClone(reservesTableFilters));
    }
  }, [isComponentVisible]);

  return (
    <div className={styles.dropdown}>
      <div className={styles.scrollable}>
        <Accordion
          text="По статусу"
          isOpenInit
          dynamicHeight
          wrapperClassName={styles.filtersAccordion}
          component={(
            <div className={styles.filtersAccordionInner}>
              {reservesInfo.statuses?.map((item) => (
                <MyCheckbox
                  key={item.id}
                  text={item.name}
                  value={filters.statuses?.includes(item.id)}
                  onClick={() => onCheckBoxClick('statuses', item)}
                  wrapperClassName={styles.filtersCheckBox}
                />
              ))}
            </div>
          )}
        />
        <Accordion
          text="По количеству"
          isOpenInit
          dynamicHeight
          wrapperClassName={styles.filtersAccordion}
          component={(
            <div className={styles.filtersAccordionInner}>
              <MyInput
                placeholder="От"
                withLeftIcon={false}
                type="number"
                value={filters.countFrom}
                onChange={(ev) => setFilters((prev) => ({ ...prev, countFrom: ev.target.value }))}
                wrapperClassName={styles.filtersMargin}
              />
              <MyInput
                placeholder="До"
                withLeftIcon={false}
                type="number"
                value={filters.countTo}
                onChange={(ev) => setFilters((prev) => ({ ...prev, countTo: ev.target.value }))}
              />
            </div>
          )}
        />
        <Accordion
          text="По сумме"
          isOpenInit
          dynamicHeight
          wrapperClassName={styles.filtersAccordion}
          component={(
            <div className={styles.filtersAccordionInner}>
              <MyInput
                placeholder="От"
                withLeftIcon={false}
                type="number"
                value={filters.sumFrom}
                onChange={(ev) => setFilters((prev) => ({ ...prev, sumFrom: ev.target.value }))}
                wrapperClassName={styles.filtersMargin}
              />
              <MyInput
                placeholder="До"
                withLeftIcon={false}
                type="number"
                value={filters.sumTo}
                onChange={(ev) => setFilters((prev) => ({ ...prev, sumTo: ev.target.value }))}
              />
            </div>
          )}
        />
        <Accordion
          text="По источнику"
          dynamicHeight
          wrapperClassName={styles.filtersAccordion}
          component={(
            <div className={styles.filtersAccordionInner}>
              {reservesInfo.sources?.map((item) => (
                <MyCheckbox
                  key={item.id}
                  text={item.name}
                  value={filters.sources?.includes(item.id)}
                  onClick={() => onCheckBoxClick('sources', item)}
                  wrapperClassName={styles.filtersCheckBox}
                />
              ))}
            </div>
          )}
        />
        {isRoot && (
          <>
            <Accordion
              text="По партнеру"
              dynamicHeight
              wrapperClassName={styles.filtersAccordion}
              component={(
                <div className={styles.filtersAccordionInner}>
                  <MyInput
                    value={checkBoxInputs.partners || ''}
                    placeholder="Поиск"
                    withCloseIcon={false}
                    onChange={(ev) => handleCheckBoxInput('partners', ev)}
                    className={styles.filtersInput}
                    wrapperClassName={styles.filtersInput}
                  />
                  {reservesInfo.companies
                    ?.filter((item) => !checkBoxInputs.partners || checkIfFiltered(item.name, checkBoxInputs.partners))
                    .map((item) => (
                      <MyCheckbox
                        key={item.id}
                        text={item.name}
                        value={filters.partners?.includes(item.id)}
                        onClick={() => onCheckBoxClick('partners', item)}
                        wrapperClassName={styles.filtersCheckBox}
                      />
                    ))}
                </div>
               )}
            />
            <Accordion
              text="По адресу"
              dynamicHeight
              wrapperClassName={styles.filtersAccordion}
              component={(
                <div className={styles.filtersAccordionInner}>
                  <MyInput
                    value={checkBoxInputs.addresses || ''}
                    placeholder="Поиск"
                    withCloseIcon={false}
                    onChange={(ev) => handleCheckBoxInput('addresses', ev)}
                    className={styles.filtersInput}
                    wrapperClassName={styles.filtersInput}
                  />
                  {reservesInfo.addresses
                    ?.filter((item) => !checkBoxInputs.addresses || checkIfFiltered(item.name, checkBoxInputs.addresses))
                    .map((item) => (
                      <MyCheckbox
                        key={item.id}
                        text={item.name}
                        value={filters.addresses?.includes(item.id)}
                        onClick={() => onCheckBoxClick('addresses', item)}
                        wrapperClassName={styles.filtersCheckBox}
                      />
                    ))}
                </div>
               )}
            />
          </>
        )}
      </div>

      <div className={styles.bottom}>
        <MyButton text="Применить" onClick={onSaveClick} className={styles.button} />
      </div>
    </div>

  );
}

export default ReservesTableFilters;
