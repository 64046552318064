import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import { useSearchParams } from 'react-router-dom';
import styles from '../css/index.module.scss';
import InputsBlock from './InputsBlock';
import MyInput from '../../../components/MyInput';
import useSinglePartnerInputs from '../../../hooks/UseSinglePartnerInputs';
import EmptyHeight from '../../../components/EmptyHeight';
import DropdownInput from '../../../components/DropdownInput';
import CalendarIcon from '../../../assets/icons/calendar.svg';
import MyDateInput from '../../../components/MyDateInput';

function SinglePartnerInfo({ savePartner }) {
  const { values, setters, errors } = useSinglePartnerInputs({ savePartner });
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev),
      selected_address_id: '',
      is_table_view: '',
    }));
  }, []);

  return (
    <div className={styles.singlePartnerInfo}>
      <InputsBlock title="Основные данные">
        <DropdownInput
          wrapperClassName={styles.input}
          value={values.active}
          onChange={setters.active}
          error={errors.active}
          data={[{ value: 'active', label: 'Активен' }, { value: 'nonActive', label: 'Заблокирован' }]}
          labelTextTop="Статус"
          placeholder="Выберите статус"
        />
        <MyInput
          wrapperClassName={styles.input}
          value={values.name}
          onChange={setters.name}
          error={errors.name}
          labelTextTop="Название компании"
          withLeftIcon={false}
        />
        <MyInput
          wrapperClassName={styles.input}
          value={values.descr}
          onChange={setters.descr}
          error={errors.descr}
          labelTextTop="Юридическое название"
          withLeftIcon={false}
        />
        <MyInput
          wrapperClassName={styles.input}
          value={values.site}
          onChange={setters.site}
          error={errors.site}
          labelTextTop="Адрес сайта"
          withLeftIcon={false}
        />
        <MyInput
          wrapperClassName={styles.inputTextArea}
          value={values.info}
          onChange={setters.info}
          error={errors.info}
          labelTextTop="Краткая информация"
          withLeftIcon={false}
          isLarge
        />
      </InputsBlock>
      <div>
        <InputsBlock title="Юридическая информация">
          <MyInput
            wrapperClassName={styles.input}
            value={values.inn}
            onChange={setters.inn}
            error={errors.inn}
            labelTextTop="ИНН"
            withLeftIcon={false}
          />
          <MyInput
            wrapperClassName={styles.input}
            value={values.kpp}
            onChange={setters.kpp}
            error={errors.kpp}
            labelTextTop="КПП"
            withLeftIcon={false}
          />
          <MyInput
            wrapperClassName={styles.input}
            value={values.number}
            onChange={setters.number}
            error={errors.number}
            labelTextTop="Номер договора"
            withLeftIcon={false}
          />
          <MyDateInput
            value={values.date}
            onChange={(value) => setters.date({ target: { value } })}
            inputProps={{
              wrapperClassName: styles.dateInputLong,
              error: errors.date,
              labelTextTop: 'Дата договора',
            }}
          />
        </InputsBlock>
        <EmptyHeight height={40} />
        <InputsBlock title="Данные руководителя">
          <MyInput
            wrapperClassName={styles.input}
            value={values.fio}
            onChange={setters.fio}
            error={errors.fio}
            labelTextTop="ФИО руководителя"
            withLeftIcon={false}
          />
          <MyInput
            wrapperClassName={styles.input}
            value={values.phone}
            onChange={setters.phone}
            error={errors.phone}
            labelTextTop="Номер телефона"
            withLeftIcon={false}
            mask="+7 999 999 99 99"
          />
          <MyInput
            value={values.email}
            onChange={setters.email}
            error={errors.email}
            labelTextTop="Email (логин)"
            withLeftIcon={false}
          />
        </InputsBlock>
      </div>
      <InputsBlock title="Задать пароль">
        <MyInput
          wrapperClassName={styles.input}
          value={values.password}
          onChange={setters.password}
          error={errors.password}
          labelTextTop="Новый пароль"
          withLeftIcon={false}
          labelTextBottom={!errors.password ? 'Мин. 8. Мин 1: верх. и ниж. регистров, цифра, символ' : ''}
          type="password"
        />
        <MyInput
          value={values.passwordRepeat}
          onChange={setters.passwordRepeat}
          error={errors.passwordRepeat}
          labelTextTop="Подтвердить пароль"
          withLeftIcon={false}
          type="password"
        />
      </InputsBlock>
    </div>
  );
}

export default SinglePartnerInfo;
