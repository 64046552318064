import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import MyTable from '../../../components/MyTable';
import { ADDRESSES_HEADERS } from '../../../constants/tables';
import styles from '../css/index.module.scss';
import convertHeadersThatAreSortable from '../../../helpers/convertHeadersThatAreSortable';
import {
  addressesFiltersAtom,
  addressesInfoAtom, selectedPartnerAddressAtom,
  selectedPartnerIdAtom,
} from '../../../atoms/partnersAtoms';
import PartnersServices from '../../../services/PartnersServices';
import convertPhoneNumber from '../../../helpers/convertPhoneNumber';
import { COLORS } from '../../../helpers/constants';
import MyInput from '../../../components/MyInput';
import IconButton from '../../../components/IconButton';
import PlanIcon from '../../../assets/icons/plan.svg';

function TableRow({ item, header, onPlanClick }) {
  switch (header.key) {
    case ADDRESSES_HEADERS.id.key: {
      return (
        <div className={classNames(styles.tableCell, styles.tableCellCenterVertical)}>
          <p className={styles.tableText}>
            {item.id}
          </p>
        </div>
      );
    }
    case ADDRESSES_HEADERS.name.key: {
      return (
        <div className={classNames(styles.tableCell, styles.tableCellCenterVertical, styles.tableCellAddress)}>
          <p className={styles.tableText}>
            {item.name}
          </p>
        </div>
      );
    }
    case ADDRESSES_HEADERS.phone.key: {
      return (
        <div className={classNames(styles.tableCell, styles.tableCellCenterVertical)}>
          <p className={styles.tableText}>
            {convertPhoneNumber(item.phone)}
          </p>
        </div>
      );
    }
    case ADDRESSES_HEADERS.active.key: {
      return (
        <div className={classNames(styles.tableCell, styles.tableCellCenterVertical)}>
          <p className={styles.tableText} style={{ color: item.active ? COLORS.primary : COLORS.red2 }}>
            {item.active ? 'Активен' : 'Заблокирован'}
          </p>
        </div>
      );
    }
    case ADDRESSES_HEADERS.plan.key: {
      return (
        <div className={classNames(styles.tableCell, styles.tableCellCenterVertical, styles.tableCellForPlan)}>
          <IconButton
            onClick={(ev) => onPlanClick(ev, item)}
            svgPath={PlanIcon}
            withEvent
          />
        </div>
      );
    }
    default: {
      return null;
    }
  }
}

function AddressesTable() {
  const [addressesInfo] = useAtom(addressesInfoAtom);
  const [addressesFilters, setAddressesFilters] = useAtom(addressesFiltersAtom);
  const [selectedPartnerId] = useAtom(selectedPartnerIdAtom);
  const [, setSelectedPartnerAddress] = useAtom(selectedPartnerAddressAtom);
  const [, setSearchParams] = useSearchParams();

  const onAddressClick = (ev, item) => {
    setSearchParams((prev) => ({ ...Object.fromEntries(prev), selected_address_id: item.id }));
  };

  const onPlanClick = (ev, item) => {
    ev.stopPropagation();
    window.open(`/plan?selected_partner_id=${selectedPartnerId}&selected_address_id=${item.id}`, '_blank');
  };

  useEffect(() => {
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev),
      selected_address_id: '',
      is_table_view: 'true',
    }));
  }, []);

  return (
    <>
      <div className={styles.addressesSearchInput}>
        <MyInput
          value={addressesFilters.searchText}
          onChange={(ev) => setAddressesFilters({ searchText: ev.target.value || undefined })}
          placeholder="Поиск"
          functionAtCleaning={() => setAddressesFilters({
            searchText: undefined,
            newDate: addressesFilters.searchText?.length ? '' : addressesFilters.date,
          })}
        />
      </div>
      <MyTable
        data={addressesInfo.addresses}
        headers={convertHeadersThatAreSortable(ADDRESSES_HEADERS, addressesInfo.sort_fields)}
        filtersAtom={addressesFiltersAtom}
        renderComponent={(val) => <TableRow {...val} onPlanClick={onPlanClick} />}
        totalPages={Math.ceil((addressesInfo.pages?.total_records || 1) / PartnersServices.ADDRESSES_PER_PAGE)}
        heightToMinus={266}
        onRowClick={onAddressClick}
      />
    </>
  );
}

export default AddressesTable;
