function WidgetPlusIcon() {
  const color = getComputedStyle(document.documentElement).getPropertyValue('--widget-button-color');
  const svg = (
    `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="${color}"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4545 11.4545H12.5455V8.54545C12.5455 8.23636 12.3091 8 12 8C11.6909 8 11.4545 8.23636 11.4545 8.54545V11.4545H8.54545C8.23636 11.4545 8 11.6909 8 12C8 12.3091 8.23636 12.5455 8.54545 12.5455H11.4545V15.4545C11.4545 15.7636 11.6909 16 12 16C12.3091 16 12.5455 15.7636 12.5455 15.4545V12.5455H15.4545C15.7636 12.5455 16 12.3091 16 12C16 11.6909 15.7636 11.4545 15.4545 11.4545Z"
        fill="${color}"
      />
    </svg>`
  );

  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

export default WidgetPlusIcon;
