import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import classNames from 'classnames';
import LogoIcon from '../../assets/images/logo.png';
import TittleIcon from '../../assets/sidebarIcons/reservista.svg';
import LogoutIcon from '../../assets/sidebarIcons/logOut.svg';
import DropdownIcon from '../../assets/sidebarIcons/dropdown.svg';
import IconButton from '../IconButton';
import './css/index.scss';
import {
  accountLoginAtom,
  closeModalAtom, isNavBarExpandedAtom,
  isNavBarExpandedAtSmallWindowAtom, isRootAtom,
  isSmallWindowAtom,
  modalStateAtom,
  tokenAtom,
} from '../../atoms/globalAtoms';
import useResize from '../../hooks/UseResize';
import LocalStorageServices from '../../services/LocalStorageServices';
import LogoutModal from '../../pages/Login/components/LogoutModal';
import { addressesAtom, modulesAtom, selectedAddressAtom } from '../../atoms/accountAtoms';
import sendRequest from '../../helpers/sendRequest';
import Api from '../../api';
import useComponentVisible from '../../hooks/UseComponentVisible';
import useNavigationBlocker from '../../hooks/UseNavigationBlocker';

function NormalNavBar({
  location,
  onLogout,
  modules,
  selectedAddress,
  addresses,
  onAddressChange,
  accountLogin,
  shouldShowAddressSelection,
  onLinkClick,

  shouldShowExpander,
  isExpanded,
  onExpandClick,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible();
  return (
    <nav className="navbar">
      <div className="navbar_top">
        <div className="navbar_logo">
          <img src={LogoIcon} alt="Reservista" className="navbar_logo_image" />
          <img src={TittleIcon} alt="Reservista" />
        </div>

        <ul className="navbar_list">
          {modules.map((item) => {
            const isActive = location.pathname === item.path;
            return (
              <li key={item.path} className="navbar_list_item">
                <div
                  onClick={() => onLinkClick(item.path)}
                  className={`navbar_item ${isActive ? 'selected' : ''}`}
                >
                  <div className="navbar_item_icon">
                    <img
                      src={item.icon}
                      alt=""
                      className={`navbar_item_icon_img ${isActive ? 'selected' : ''}`}
                    />
                  </div>
                  <span className="navbar_item_text">{item.title}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="navbar_logout">
        <div className={`sidebar_addresses_popup ${isComponentVisible && 'sidebar_addresses_popup_visible'}`} ref={ref}>
          {addresses.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                onAddressChange(item);
                setIsComponentVisible(false);
              }}
              className={`sidebar_addresses_popup_item ${item.id === selectedAddress?.id && 'sidebar_addresses_popup_item_selected'}`}
            >
              {item.name}
            </div>
          ))}
        </div>

        {shouldShowExpander && (
          <div onClick={onExpandClick} className="navbar_expander">
            {isExpanded ? 'Закрыть' : 'Раскрыть'}
            {' '}
            боковое меню
          </div>
        )}

        {shouldShowAddressSelection && (
          <div className="navbar_logout_info" onClick={() => setIsComponentVisible(true)}>
            <div className="navbar_logout_user">
              <div className="navbar_logout_bold">{selectedAddress?.name}</div>
              <div className="navbar_logout_name">{selectedAddress?.descr}</div>
            </div>
            <div>
              <img
                className="navbar_logout_expand"
                src={DropdownIcon}
                alt="Expand"
              />
            </div>
          </div>
        )}
        <div className="navbar_logout_out">
          <div className="navbar_logout_bold navbar_logout_login">
            {accountLogin}
          </div>
          <IconButton
            wrapperClassName="navbar_logout_out_button"
            svgPath={LogoutIcon}
            onClick={onLogout}
          />
        </div>
      </div>
    </nav>
  );
}

function SmallNavBar({ location, onLogout, modules }) {
  return (
    <nav className="navbar small">
      <div className="navbar_top">
        <div className="navbar_logo">
          <img src={LogoIcon} alt="Reservista" className="navbar_logo_image" />
        </div>

        <ul className="navbar_list">
          {modules.map((item) => {
            const isActive = location.pathname === item.path;
            return (
              <li key={item.path} className="navbar_list_item">
                <div
                  onClick={() => onLinkClick(item.path)}
                  className={`navbar_item ${isActive ? 'selected' : ''}`}
                >
                  <div className="navbar_item_icon">
                    <img
                      src={item.icon}
                      alt=""
                      className={`navbar_item_icon_img ${isActive ? 'selected' : ''}`}
                    />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div>
        <div className="navbar_logout">
          <div className="navbar_logout_out">
            <IconButton
              wrapperClassName="navbar_logout_out_button"
              svgPath={LogoutIcon}
              onClick={onLogout}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}

function Sidebar() {
  const location = useLocation();
  const { width: windowWidth } = useResize();
  const [isRoot] = useAtom(isRootAtom);
  const [isSmallWindow, setIsSmallWindow] = useAtom(isSmallWindowAtom);
  const [isNavBarExpandedAtSmallWindow, setIsNavBarExpandedAtSmallWindow] = useAtom(isNavBarExpandedAtSmallWindowAtom);
  const [isNavBarExpandedWindow, setIsNavBarExpandedWindow] = useAtom(isNavBarExpandedAtom);
  const [isExpandedNavBarClosing, setIsExpandedNavBarClosing] = useState(false);
  const [, setToken] = useAtom(tokenAtom);
  const [, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [modulesRaw] = useAtom(modulesAtom);
  const modules = modulesRaw?.filter((i) => i.show !== false);
  const [selectedAddress, setSelectedAddress] = useAtom(selectedAddressAtom);
  const [addresses] = useAtom(addressesAtom);
  const [accountLogin] = useAtom(accountLoginAtom);

  const shouldShowAddressSelection = isRoot ? !!selectedAddress?.id && location.pathname === '/plan' : !!selectedAddress?.id;

  const { onLinkClick } = useNavigationBlocker();

  const onCloseExpandedNavbar = () => {
    setIsExpandedNavBarClosing(true);
    setTimeout(() => {
      setIsNavBarExpandedAtSmallWindow(false);
    }, 300);
  };

  const onLogout = () => {
    onCloseExpandedNavbar();
    setModalState({
      isOpen: true,
      children: <LogoutModal
        onClose={closeModal}
        onLogout={async () => {
          closeModal();
          await sendRequest({
            request: Api.logout,
            warnErrorText: 'while logout',
          });
          setToken('');
          LocalStorageServices.setToken('');
          window.location.reload();
        }}
      />,
    });
  };

  const onAddressChange = (val) => {
    setSelectedAddress(val);
  };

  useEffect(() => {
    setIsSmallWindow(windowWidth < 1200);
  }, [windowWidth]);

  useEffect(() => {
    if (isNavBarExpandedAtSmallWindow === false) {
      setIsExpandedNavBarClosing(false);
    }
  }, [isNavBarExpandedAtSmallWindow]);

  useEffect(() => {
    if (isSmallWindow === false) {
      setIsNavBarExpandedAtSmallWindow(false);
    }
  }, [isSmallWindow]);

  return (
    isSmallWindow || !isNavBarExpandedWindow
      ? (
        <>
          {isNavBarExpandedAtSmallWindow && (
          <div
            className={`floating_navbar_bg ${isExpandedNavBarClosing && 'floating_navbar_bg_closing'}`}
            onMouseMove={onCloseExpandedNavbar}
          >
            <div
              className="floating_navbar_part"
              onMouseMove={(ev) => ev.stopPropagation()}
            >
              <div className="floating_navbar">
                <NormalNavBar
                  location={location}
                  onLogout={onLogout}
                  modules={modules}
                  selectedAddress={selectedAddress}
                  addresses={addresses}
                  onAddressChange={onAddressChange}
                  accountLogin={accountLogin}
                  shouldShowAddressSelection={shouldShowAddressSelection}
                  onLinkClick={onLinkClick}
                  shouldShowExpander={!isSmallWindow}
                  isExpanded={isNavBarExpandedWindow}
                  onExpandClick={() => setIsNavBarExpandedWindow((prev) => !prev)}
                />
              </div>
            </div>
          </div>
          )}
          <div onMouseMove={() => setIsNavBarExpandedAtSmallWindow(true)}>
            <SmallNavBar
              location={location}
              setIsNavBarExpandedAtSmallWindow={setIsNavBarExpandedAtSmallWindow}
              onLogout={onLogout}
              modules={modules}
              onLinkClick={onLinkClick}
            />
          </div>
        </>
      )
      : (
        <NormalNavBar
          location={location}
          onLogout={onLogout}
          modules={modules}
          selectedAddress={selectedAddress}
          addresses={addresses}
          onAddressChange={onAddressChange}
          accountLogin={accountLogin}
          shouldShowAddressSelection={shouldShowAddressSelection}
          onLinkClick={onLinkClick}
          shouldShowExpander
          isExpanded={isNavBarExpandedWindow}
          onExpandClick={() => setIsNavBarExpandedWindow((prev) => !prev)}
        />
      )
  );
}

export default Sidebar;
