import React from 'react';
import { useAtom } from 'jotai/index';
import MyTable from '../../../components/MyTable';
import { CONTACTS_HEADERS } from '../../../constants/tables';
import styles from '../css/index.module.scss';
import convertHeadersThatAreSortable from '../../../helpers/convertHeadersThatAreSortable';
import { contactsFiltersAtom, contactsInfoAtom } from '../../../atoms/contactsAtoms';
import ContactsServices from '../../../services/ContactsServices';
import convertPhoneNumber from '../../../helpers/convertPhoneNumber';
import ColorfulTag from '../../../components/ColorfulTag';

function TableRow({ item, header }) {
  switch (header.key) {
    case CONTACTS_HEADERS.name.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.name}
          </p>
        </div>
      );
    }
    case CONTACTS_HEADERS.phone.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {convertPhoneNumber(item.phone)}
          </p>
        </div>
      );
    }
    case CONTACTS_HEADERS.reservations.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.reservations}
          </p>
        </div>
      );
    }
    case CONTACTS_HEADERS.visits.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.visits}
          </p>
        </div>
      );
    }

    case CONTACTS_HEADERS.labels.key: {
      return (
        <div className={styles.tableCell}>
          <div className={styles.tableLabels}>
            {item.labels.map((label) => (
              <ColorfulTag key={label.id} bgColor="#E4FEE4" text={label.name} />
            ))}
          </div>
        </div>
      );
    }
    default: {
      return null;
    }
  }
}

function ContactsTable() {
  const [contactsInfo] = useAtom(contactsInfoAtom);

  return (
    <MyTable
      data={contactsInfo.clients}
      headers={convertHeadersThatAreSortable(CONTACTS_HEADERS, contactsInfo.sort_fields)}
      filtersAtom={contactsFiltersAtom}
      renderComponent={(val) => <TableRow {...val} />}
      totalPages={Math.ceil((contactsInfo.pages?.total_records || 1) / ContactsServices.CONTACTS_PER_PAGE)}
      heightToMinus={146}
    />
  );
}

export default ContactsTable;
