import store from '../atoms';
import { openInfoModalAtom } from '../atoms/globalAtoms';
import sleep from './sleep';

export default async ({
  request,
  setLoading,
  warnErrorText,
  payload,
  showErrorInModal = true,
}) => {
  try {
    if (setLoading) {
      setLoading(true);
    }
    const { data } = await request(payload);
    return data;
  } catch (e) {
    console.warn(e?.response?.data, warnErrorText);
    if (showErrorInModal) {
      sleep(200).then(() => (
        store.set(openInfoModalAtom, { title: 'Ошибка', text: e?.response?.data?.message })
      ));
    }
    return e?.response?.data;
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};
