function WidgetArrowIcon() {
  const color = getComputedStyle(document.documentElement).getPropertyValue('--widget-background-color');
  const svg = (
    `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8002 2.3999L7.55375 11.6463C7.35849 11.8416 7.35849 12.1582 7.55375 12.3535L16.8002 21.5999"
        stroke="${color}"
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>`

  );

  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

export default WidgetArrowIcon;
