import React from 'react';
import { ATTRIBUTES_TABLE_DATA, ATTRIBUTES_TABLE_HEADERS } from '../../../constants/integration';

import styles from '../css/index.module.scss';

function AttributesTable() {
  return (
    <table className={styles.attributesTable}>
      <thead>
        <tr>
          {ATTRIBUTES_TABLE_HEADERS.map((item, index) => (
            <th
              key={index}
              style={{ width: index === 1 ? '100px' : '243.33px' }}
            >
              <p className={styles.attributesTableCell}>
                {item}
              </p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {ATTRIBUTES_TABLE_DATA.map((data, dataIndex) => (
          <tr key={dataIndex}>
            {data.map((item, index) => (
              <td
                key={index}
                style={{ width: index === 1 ? '100px' : '243.33px' }}
              >
                <p className={styles.attributesTableCell}>
                  {item}
                </p>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default AttributesTable;
