import { atom } from 'jotai';
import { PARTNERS_PAGE_STATES } from '../constants/statuses';

const partnersFiltersAtom = atom({
  // for properly updating
  date: new Date().toISOString(),
}, (get, set, update) => {
  set(partnersFiltersAtom, {
    ...get(partnersFiltersAtom),
    page: 1,
    ...update,

    // for properly updating
    newDate: null,
    date: update.newDate || new Date().toISOString(),
  });
});

const addressesFiltersAtom = atom({
  // for properly updating
  date: new Date().toISOString(),
}, (get, set, update) => {
  set(addressesFiltersAtom, {
    ...get(addressesFiltersAtom),
    page: 1,
    ...update,

    // for properly updating
    newDate: null,
    date: update.newDate || new Date().toISOString(),
  });
});

const partnersInfoAtom = atom({});
const addressesInfoAtom = atom({});
const gettingPartnersLoadingAtom = atom(false);

const partnersPageStateAtom = atom(PARTNERS_PAGE_STATES.partnersList);

const selectedPartnerIdAtom = atom(null);
const selectedAddressIdAtom = atom(null);

const selectedPartnerAtom = atom(null);
const selectedPartnerAddressAtom = atom(null);

const partnerSaveAtom = atom(0);
const addressSaveAtom = atom(0);

const isPartnersInputsDirtyAtom = atom(false);

export {
  partnersPageStateAtom,
  partnersFiltersAtom,
  partnersInfoAtom,
  gettingPartnersLoadingAtom,
  selectedPartnerIdAtom,
  selectedAddressIdAtom,
  selectedPartnerAtom,
  selectedPartnerAddressAtom,
  partnerSaveAtom,
  addressSaveAtom,
  addressesInfoAtom,
  addressesFiltersAtom,
  isPartnersInputsDirtyAtom,
};
