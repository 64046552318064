import React from 'react';
import { Chart } from 'react-charts';
import { useAtom } from 'jotai/index';
import { PieChart } from 'react-minimal-pie-chart';
import styles from '../css/index.module.scss';
import ReservesChartWrapper from './ReservesChartWrapper';
import { RESERVES_CHARTS } from '../../../constants/charts';
import useReservesCharts from '../../../hooks/UseReservesCharts';
import { reservesInfoAtom } from '../../../atoms/reservesAtoms';
import EmptyHeight from '../../../components/EmptyHeight';
import { zoomOutRatioAtom } from '../../../atoms/globalAtoms';

function ReservesCharts() {
  const [reservesInfo] = useAtom(reservesInfoAtom);
  const [zoomOutRatio] = useAtom(zoomOutRatioAtom);

  const {
    firstData,
    secondData,
    thirdData,
    fourthData,
    primaryAxis,
    secondaryAxes,
    getDatumStyle,
    primaryAxisHorizontal,
    secondaryAxesHorizontal,
  } = useReservesCharts(reservesInfo?.reservations || []);

  return (
    <div
      className={styles.charts}
    >
      <div className={styles.chartsRow}>
        <ReservesChartWrapper
          width={920}
          height={614}
          title="Резервы и гости по датам"
          headerPadding={40}
          points={RESERVES_CHARTS.points.first}
        >
          {reservesInfo?.reservations?.length ? (
            <Chart
              options={{
                data: firstData,
                primaryAxis,
                secondaryAxes,
                defaultColors: [RESERVES_CHARTS.colors.pink, RESERVES_CHARTS.colors.blue],
                getDatumStyle,
                primaryCursor: false,
                secondaryCursor: false,
              }}
            />
          ) : null}
        </ReservesChartWrapper>
        <ReservesChartWrapper
          width={744}
          height={614}
          title="Статусы"
          headerPadding={0}
          points={RESERVES_CHARTS.points.second}
          considerZoom={false}
        >
          {reservesInfo?.reservations?.length ? (
            <PieChart
              data={secondData}
              animate
              radius={40}
            />
          ) : null}
        </ReservesChartWrapper>
      </div>
      <EmptyHeight height={40} />
      <ReservesChartWrapper
        width={1708}
        height={639}
        title="Резервы и гости по часам"
        headerPadding={40}
        points={RESERVES_CHARTS.points.third}
      >
        {reservesInfo?.reservations?.length ? (
          <Chart
            options={{
              data: thirdData,
              primaryAxis,
              secondaryAxes,
              defaultColors: [RESERVES_CHARTS.colors.pink, RESERVES_CHARTS.colors.blue],
              getDatumStyle,
              primaryCursor: false,
              secondaryCursor: false,
            }}
          />
        ) : null}
      </ReservesChartWrapper>
      <EmptyHeight height={40} />
      <ReservesChartWrapper
        width={840}
        height={566}
        title="По источнику резерва"
        headerPadding={112}
        points={RESERVES_CHARTS.points.fourth}
      >
        {reservesInfo?.reservations?.length ? (
          <Chart
            options={{
              data: fourthData,
              primaryAxis: primaryAxisHorizontal,
              secondaryAxes: secondaryAxesHorizontal,
              defaultColors: [RESERVES_CHARTS.colors.yellow, RESERVES_CHARTS.colors.green],
              getDatumStyle,
              primaryCursor: false,
              secondaryCursor: false,
            }}
          />
        ) : null}
      </ReservesChartWrapper>
    </div>
  );
}

export default ReservesCharts;
