import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const usePathnameChange = (onPathnameChange) => {
  const location = useLocation();
  const previousPathname = useRef(location.pathname);

  useEffect(() => {
    if (previousPathname.current !== location.pathname) {
      onPathnameChange(location.pathname);
      previousPathname.current = location.pathname;
    }
  }, [location.pathname, onPathnameChange]);
};

export default usePathnameChange;
