import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';
import styles from './css/index.module.scss';
import useWidget from '../../hooks/UseWidget';
import {
  widgetDataAtom,
  widgetParamsAtom,
  widgetScreenStateAtom,
  widgetSelectedLanguageAtom,
} from '../../atoms/widgetAtoms';
import WidgetHeader from './components/WidgetHeader';
import MyModal from '../../components/MyModal';
import widgetStates from './constants/widgetStates';
import WidgetSlotsState from './components/WidgetSlotsState';
import WidgetInputsState from './components/WidgetInputsState';

function Widget() {
  const {
    t, initWidgetData, getSlots, requestToCloseWidget,
  } = useWidget();
  const [searchParams] = useSearchParams();

  const [widgetData] = useAtom(widgetDataAtom);
  const [widgetParams] = useAtom(widgetParamsAtom);
  const [widgetScreenState] = useAtom(widgetScreenStateAtom);
  const [widgetSelectedLanguage] = useAtom(widgetSelectedLanguageAtom);

  moment.locale(widgetSelectedLanguage.code || 'en');

  useEffect(() => {
    initWidgetData();
  }, []);

  useEffect(() => {
    if (!widgetData?.id) return;

    const timer = setTimeout(() => {
      getSlots().then();
    }, 400);

    return () => clearTimeout(timer);
  }, [widgetParams, widgetData?.id]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.action === 'close') {
        requestToCloseWidget();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [requestToCloseWidget]);

  return (
    <div className={styles.wrapper}>
      <WidgetHeader />

      <div className={styles.container}>

        {widgetScreenState === widgetStates.slots
          ? <WidgetSlotsState />
          : widgetScreenState === widgetStates.inputs
            ? <WidgetInputsState />
            : null}
      </div>

      <MyModal />
    </div>
  );
}

export default Widget;
