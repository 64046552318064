import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import { useSearchParams } from 'react-router-dom';
import MyTable from '../../../components/MyTable';
import { PARTNERS_HEADERS } from '../../../constants/tables';
import styles from '../css/index.module.scss';
import convertHeadersThatAreSortable from '../../../helpers/convertHeadersThatAreSortable';
import { partnersFiltersAtom, partnersInfoAtom, selectedPartnerIdAtom } from '../../../atoms/partnersAtoms';
import PartnersServices from '../../../services/PartnersServices';
import convertPhoneNumber from '../../../helpers/convertPhoneNumber';
import { COLORS } from '../../../helpers/constants';

function TableRow({ item, header }) {
  switch (header.key) {
    case PARTNERS_HEADERS.id.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.id}
          </p>
        </div>
      );
    }
    case PARTNERS_HEADERS.name.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.name}
          </p>
        </div>
      );
    }
    case PARTNERS_HEADERS.phone.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {convertPhoneNumber(item.phone)}
          </p>
        </div>
      );
    }
    case PARTNERS_HEADERS.email.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.email}
          </p>
        </div>
      );
    }
    case PARTNERS_HEADERS.active.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText} style={{ color: item.active ? COLORS.primary : COLORS.red2 }}>
            {item.active ? 'Активен' : 'Заблокирован'}
          </p>
        </div>
      );
    }
    default: {
      return null;
    }
  }
}

function PartnersTable() {
  const [partnersInfo] = useAtom(partnersInfoAtom);
  const [, setSelectedPartnerId] = useAtom(selectedPartnerIdAtom);
  const [, setSearchParams] = useSearchParams();
  const [, setPartnersFilters] = useAtom(partnersFiltersAtom);

  useEffect(() => {
    // for updating
    setPartnersFilters({ newDate: new Date().toISOString() });
  }, []);

  const onPartnerClick = (ev, item) => {
    setSearchParams({ selected_partner_id: item.id, is_table_view: false });
  };

  useEffect(() => {
    setSelectedPartnerId(null);
  }, []);

  return (
    <MyTable
      data={partnersInfo.partners}
      headers={convertHeadersThatAreSortable(PARTNERS_HEADERS, partnersInfo.sort_fields)}
      filtersAtom={partnersFiltersAtom}
      renderComponent={(val) => <TableRow {...val} />}
      totalPages={Math.ceil((partnersInfo.pages?.total_records || 1) / PartnersServices.PARTNERS_PER_PAGE)}
      heightToMinus={196}
      onRowClick={onPartnerClick}
    />
  );
}

export default PartnersTable;
