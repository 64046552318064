import moment from 'moment';
import widgetLanguages from '../pages/Widget/constants/widgetLanguages';

class WidgetsServices {
  static sortedWidgetLanguages = (selected) => [selected, ...Object.values(widgetLanguages).filter((i) => i.code !== selected.code)];

  static isValidAndFutureOrToday = (dateString) => {
    const format = 'YYYY-MM-DD';
    const today = moment().startOf('day');

    const isValidFormat = moment(dateString, format, true).isValid();

    if (!isValidFormat) {
      return false;
    }

    const inputDate = moment(dateString, format);
    return inputDate.isSameOrAfter(today);
  };

  static convertPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== 'string') return;
    if (phoneNumber[0] !== '+') phoneNumber = `+${phoneNumber}`;

    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)} ${phoneNumber.slice(5, 8)} ${phoneNumber.slice(8, 10)} ${phoneNumber.slice(10, 12)}`;
  };

  static checkIfColorIsValid = (color) => {
    const hexColorRegex = /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/;
    return hexColorRegex.test(color);
  };
}

export default WidgetsServices;
