import { api } from './configs';
import { SERVER_URL } from '../constants';

class Api {
  static url = SERVER_URL;

  /* Account Start */

  static auth(body) {
    return api.post('/account/auth', body);
  }

  static init() {
    return api.get('/account/init');
  }

  static logout() {
    return api.get('/account/logout');
  }

  /* Account End */

  /* Halls Start */

  static getHalls(params) {
    return api.get('/account/zones/list', { params });
  }

  static saveHall(body) {
    return api.post('/account/zones/save', body);
  }

  static addHall(body) {
    return api.post('/account/zones/create', body);
  }

  static renameHall(body) {
    return api.post('/account/zones/rename', body);
  }

  static deleteHall(body) {
    return api.post('/account/zones/delete', body);
  }

  /* Halls End */

  /* Reserves Start */

  static getReserves(body) {
    return api.post('/account/reservations/list', body);
  }

  static getAddressesOfPartners(body) {
    return api.post('/account/directories/addresses', body);
  }

  static cancelReserve({ id }) {
    return api.delete(`/account/reservations/${id}/cancel`);
  }

  /* Reserves End */

  /* Contacts Start */

  static getContacts(body) {
    return api.post('/account/contacts/list', body);
  }

  static saveImportedContacts(body) {
    return api.post('/account/contacts/import', body);
  }

  /* Contacts End */

  /* Partners Start */

  static getPartners(body) {
    return api.post('/account/partners/list', body);
  }

  static getPartner(id) {
    return api.get(`/account/partners/${id}/edit `);
  }

  static savePartner(body) {
    return api.post('/account/partners/save', body);
  }

  static getAddresses(body) {
    return api.post(`/account/partners/${body.id}/addresses/list`, body);
  }

  static getAddress({ partnerId, id }) {
    return api.get(`/account/partners/${partnerId}/addresses/${id}/edit `);
  }

  static saveAddress({ partnerId, ...body }) {
    return api.post(`/account/partners/${partnerId}/addresses/save `, body);
  }

  /* Partners End *

  /* Widget Start */

  static getWidgetSlots({ id, ...body }) {
    return api.post(`/widget/${id}/get_time_slots `, body);
  }

  static makeWidgetBook({ id, ...body }) {
    return api.post(`/widget/${id}/create `, body);
  }

  /* Widget End */
}

export default Api;
