import React from 'react';
import classNames from 'classnames';
import ContactsImport1 from '../../../assets/images/contactsImport1.png';
import ContactsImport2 from '../../../assets/images/contactsImport2.png';
import CloseIcon from '../../../assets/formIcons/close.svg';
import styles from '../css/index.module.scss';
import IconButton from '../../../components/IconButton';
import EmptyWidth from '../../../components/EmptyWidth';

function ContactsImportInfoModal({ closeModal }) {
  return (
    <div className={classNames('modal_wrapper', styles.infoModal)}>
      <div className={styles.infoModalHeader}>
        <EmptyWidth width={48} />
        <p className="modal_title_no_margin">Информация по импорту контактов</p>
        <IconButton svgPath={CloseIcon} onClick={closeModal} />
      </div>
      <div className={styles.infoModalContainer}>

        <p className={styles.infoModalText}>1. Порядок столбцов</p>
        <p className={styles.infoModalTextSmall}>1 столбец: ФИО, 2 столбец: Телефон, 3 столбец: комментарии к гостю (разделять запятыми)</p>
        <img src={ContactsImport1} alt="" />
        <p className={classNames(styles.infoModalText, styles.infoModalTextSecond)}>2. Сохранение файла для импорта</p>
        <p className={styles.infoModalTextSmall}>Необходимо сохранять файл в формате .csv (windows comma separated)</p>
        <img src={ContactsImport2} alt="" />
      </div>
    </div>
  );
}

export default ContactsImportInfoModal;
