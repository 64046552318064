import { atom } from 'jotai';
import moment from 'moment';
import widgetLanguages from '../pages/Widget/constants/widgetLanguages';
import widgetStates from '../pages/Widget/constants/widgetStates';

const widgetDataAtom = atom({
  id: null,
  name: '',
  max_guests: null,
  slots: [],
}, (get, set, update) => {
  const newObj = { ...get(widgetDataAtom), ...update };
  set(widgetDataAtom, newObj);
});

const widgetParamsAtom = atom({
  date: new Date(),
  persons: 2,
}, (get, set, update) => {
  const newObj = { ...get(widgetParamsAtom), ...update };
  set(widgetParamsAtom, newObj);
});

const widgetInputsAtom = atom({
  slot: null,
  name: '',
  phoneNumber: '',
  comment: '',
}, (get, set, update) => {
  const newObj = { ...get(widgetInputsAtom), ...update };
  set(widgetInputsAtom, newObj);
});

const widgetSelectedLanguageAtom = atom(widgetLanguages.ru);

const widgetGettingLoadingAtom = atom(false);

const widgetScreenStateAtom = atom(widgetStates.slots);

export {
  widgetDataAtom,
  widgetParamsAtom,
  widgetGettingLoadingAtom,
  widgetSelectedLanguageAtom,
  widgetScreenStateAtom,
  widgetInputsAtom,
};
