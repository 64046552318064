function convertSchedule(schedule) {
  return schedule.map((i) => ({
    weekday: i.weekday || '',
    open_time: i.open_time || '',
    close_time: i.close_time || '',
    day_off: i.day_off || '',
    date: i.date || '',
  })).sort((a, b) => (a.weekday > b.weekday ? 1 : a.weekday < b.weekday ? -1 : a.date > b.date ? 1 : -1));
}

export default convertSchedule;
