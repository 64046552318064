import React from 'react';
import Draggable from 'react-draggable';
import { useAtom } from 'jotai/index';
import classNames from 'classnames';
import styles from './css/index.module.scss';
import { gridZoomScaleAtom, objectsListAtom, selectedObjectIndexAtom } from '../../../../atoms/schemeAtoms';
import SimpleObject from './ObjectTypes/SimpleObject';
import AnyObject from './ObjectTypes/AnyObject';
import { zoomOutRatioAtom } from '../../../../atoms/globalAtoms';

function PlanObjects() {
  const [objectsList, setObjectsList] = useAtom(objectsListAtom);
  const [selectedObjectIndex, setSelectedObjectIndex] = useAtom(selectedObjectIndexAtom);
  const [gridZoomScale, setGridZoomScale] = useAtom(gridZoomScaleAtom);
  const [zoomOutRatio] = useAtom(zoomOutRatioAtom);

  const onObjectClick = (index) => (ev) => {
    ev.stopPropagation();
    setSelectedObjectIndex(index);
  };

  const onDrag = (index) => (ev, data) => {
    const currentObject = objectsList[index];

    // considering grid scale and window zoom
    const diffX = data.x - currentObject.fields.x;
    const changeX = (diffX - (gridZoomScale * zoomOutRatio) * diffX) / (gridZoomScale * zoomOutRatio);
    const diffY = data.y - currentObject.fields.y;
    const changeY = (diffY - (gridZoomScale * zoomOutRatio) * diffY) / (gridZoomScale * zoomOutRatio);

    const newObjectsList = [...objectsList];
    newObjectsList[index].fields.x = data.x + changeX;
    newObjectsList[index].fields.y = data.y + changeY;
    setObjectsList(newObjectsList);
  };

  return (
    objectsList.map((object, index) => (
      <Draggable
        key={object.id}
        onStart={(ev) => ev.stopPropagation()}
        onDrag={onDrag(index)}
        position={{ x: object.fields.x, y: object.fields.y }}
      >
        <div
          onClick={onObjectClick(index)}
          className={styles.object}
        >
          {object.type === 'anything' ? (
            <AnyObject object={object} isSelected={index === selectedObjectIndex} />
          ) : (
            <SimpleObject object={object} isSelected={index === selectedObjectIndex} />
          )}
          <div className={classNames(styles.objectTexts, { [styles.objectTextsSmall]: object.type === 'chair' })}>
            <p className={styles.objectTitle}>{object.fields.title}</p>
            {(object.fields.minCap || object.fields.maxCap) && (
              <p className={styles.objectCap}>
                {object.fields.minCap}
                -
                {object.fields.maxCap}
              </p>
            )}
          </div>
        </div>
      </Draggable>
    ))
  );
}

export default PlanObjects;
