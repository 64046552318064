import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import styles from './css/index.module.scss';
import ReservesHeader from './components/ReservesHeader';
import useReserves from '../../hooks/UseReserves';
import ReservesTable from './components/ReservesTable';
import { reservesModeAtom } from '../../atoms/reservesAtoms';
import ReservesCharts from './components/ReservesCharts';

function Reserves() {
  const { cancelReserve } = useReserves();
  const [reservesMode, setReservesMode] = useAtom(reservesModeAtom);

  useEffect(() => {
    setReservesMode('table');
  }, []);

  return (
    <div className={styles.reserves}>
      <ReservesHeader />
      {reservesMode === 'table'
        ? <ReservesTable cancelReserve={cancelReserve} />
        : <ReservesCharts />}
    </div>
  );
}

export default Reserves;
