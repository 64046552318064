export default {
  ru: {
    name: 'RU',
    code: 'ru',
  },
  en: {
    name: 'EN',
    code: 'en',
  },
};
