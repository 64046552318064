import React, { useMemo } from 'react';
import { useAtom } from 'jotai/index';
import classNames from 'classnames';
import moment from 'moment';
import styles from '../css/index.module.scss';
import { widgetInputsAtom, widgetParamsAtom } from '../../../atoms/widgetAtoms';
import useWidget from '../../../hooks/UseWidget';
import WT from '../constants/widgetTranslations';
import EmptyHeight from '../../../components/EmptyHeight';
import PartnersServices from '../../../services/PartnersServices';
import MyButton from '../../../components/MyButton';
import makeFirstUppercase from '../../../helpers/makeFirstUppercase';
import MyInput from '../../../components/MyInput';

function WidgetInputsState() {
  const { t, makeBook } = useWidget();
  const [widgetParams] = useAtom(widgetParamsAtom);
  const [widgetInputs, setWidgetInputs] = useAtom(widgetInputsAtom);

  const isDisabled = useMemo(() => (
    !widgetInputs.name.length || !widgetInputs.comment.length || widgetInputs.phoneNumber.replaceAll('_', '').length !== 16
  ), [widgetInputs]);

  return (
    <>
      <div className={styles.inputsState}>
        <p className={styles.inputsTitle}>
          {makeFirstUppercase(moment(widgetParams.date).format('dddd, DD MMMM'))}
          {' '}
          {PartnersServices.convertSecondsToFormat(widgetInputs.slot)}
          ,
          {' '}
          {widgetParams.persons}
          {' '}
          {t(WT.person, widgetParams.persons)}
        </p>
        <EmptyHeight height={50} />

        <MyInput
          value={widgetInputs.name}
          onChange={(ev) => setWidgetInputs({ name: ev.target.value })}
          labelTextTop={t(WT.yourName)}
          placeholder={t(WT.namePlaceholder)}
          withLeftIcon={false}
          wrapperClassName={classNames(styles.input)}
          forWidget
        />
        <EmptyHeight height={50} />

        <MyInput
          value={widgetInputs.phoneNumber}
          onChange={(ev) => setWidgetInputs({ phoneNumber: ev.target.value })}
          labelTextTop={t(WT.yourNumber)}
          placeholder="+7"
          withLeftIcon={false}
          wrapperClassName={classNames(styles.input)}
          forWidget
          mask="+7 999 999 99 99"
        />
        <EmptyHeight height={23} />

        <MyInput
          value={widgetInputs.comment}
          onChange={(ev) => setWidgetInputs({ comment: ev.target.value })}
          placeholder={t(WT.bookingRequests)}
          withLeftIcon={false}
          wrapperClassName={classNames(styles.input, styles.inputLarge)}
          isLarge
          forWidget
        />
      </div>
      <div>
        <MyButton
          text={t(WT.book)}
          className={classNames(styles.myButton, { [styles.myButtonDisabled]: isDisabled })}
          disabled={isDisabled}
          onClick={() => !isDisabled && makeBook()}
        />
        <EmptyHeight height={16} />
        <p className={styles.policyText}>{t(WT.policy)}</p>
      </div>
    </>
  );
}

export default WidgetInputsState;
