import React from 'react';
import Draggable from 'react-draggable';
import { useAtom } from 'jotai';
import styles from './css/index.module.scss';
import PlanServices from '../../../../services/PlanServices';
import { gridZoomScaleAtom, schemeDotsAtom } from '../../../../atoms/schemeAtoms';
import { zoomOutRatioAtom } from '../../../../atoms/globalAtoms';

function SchemeDots() {
  const [schemeDots, setSchemeDots] = useAtom(schemeDotsAtom);
  const [gridZoomScale, setGridZoomScale] = useAtom(gridZoomScaleAtom);
  const [zoomOutRatio] = useAtom(zoomOutRatioAtom);

  const onDrag = (index) => (ev, data) => {
    const newDots = [...schemeDots];

    // considering grid scale and window zoom
    const diffX = (data.x - newDots[index][0]);
    const changeX = (diffX - (gridZoomScale * zoomOutRatio) * diffX) / (gridZoomScale * zoomOutRatio);

    const diffY = (data.y - newDots[index][1]);
    const changeY = (diffY - (gridZoomScale * zoomOutRatio) * diffY) / (gridZoomScale * zoomOutRatio);

    newDots[index] = [data.x + changeX, data.y + changeY, ...newDots[index].slice(2)];
    setSchemeDots(newDots);
  };

  const onDoubleClick = (index) => () => {
    if (schemeDots.length <= 2) return;
    const newDots = [...schemeDots];
    newDots.splice(index, 1);
    setSchemeDots(newDots);
  };

  return (
    schemeDots.map((dot, index) => (
      <Draggable
        onStart={(ev) => ev.stopPropagation()}
        key={dot[2]}
        onDrag={onDrag(index)}
        position={{ x: dot[0], y: dot[1] }}
      >
        <div
          onDoubleClick={onDoubleClick(index)}
          className={styles.dot}
        >
          <div
            className={styles.dotInner}
            style={{
              width: `${PlanServices.dotSize}px`,
              height: `${PlanServices.dotSize}px`,
              borderRadius: `${PlanServices.dotSize / 2}px`,
            }}
          />
        </div>

      </Draggable>
    ))
  );
}

export default SchemeDots;
