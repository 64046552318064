function WidgetMinusIcon() {
  const color = getComputedStyle(document.documentElement).getPropertyValue('--widget-button-color');
  const svg = (
    `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="${color}"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.54545 12.5999H15.4545C15.7636 12.5999 16 12.3399 16 11.9999C16 11.6599 15.7636 11.3999 15.4545 11.3999H8.54545C8.23636 11.3999 8 11.6599 8 11.9999C8 12.3399 8.23636 12.5999 8.54545 12.5999Z"
        fill="${color}"
      />
    </svg>`

  );

  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

export default WidgetMinusIcon;
