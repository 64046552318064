import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { ru } from 'date-fns/locale';
import moment from 'moment/moment';
import styles from './css/index.module.scss';
import './css/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import MyInput from '../MyInput';
import arrowRightViolet from '../../assets/icons/arrowRightViolet.svg';
import calendarIcon from '../../assets/icons/calendar.svg';
import { DATE_FORMAT } from '../../constants';

function MyDateInput({
  value, onChange, inputProps,
}) {
  const customModifier = () => [
    {
      name: 'myModifier',
      fn(state) {
        state.x += 200 - 60;
        return state;
      },
    },
  ];

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      dateFormat="dd.MM.yyyy"
      customInput={<CustomInput {...inputProps} />}
      locale={ru}
      renderCustomHeader={CustomHeader}
      popperModifiers={customModifier()}
    />
  );
}

const CustomInput = React.forwardRef(({
  value, onClick, onChange, ...p
}, ref) => (
  <div onClick={onClick} ref={ref}>
    <MyInput
      {...p}
      placeholder="01.01.2001"
      value={value}
      withLeftIcon={false}
      withCloseIcon={false}
      functionAtCleaning={() => onChange(null)}
      floatingComponent={<img className={styles.inputImg} src={calendarIcon} alt="" />}
      className={classNames(styles.input, p.className)}
      wrapperClassName={classNames(styles.inputWrapper, p.wrapperClassName)}
    />
  </div>
));

function CustomHeader({ date, decreaseMonth, increaseMonth }) {
  const convertDate = (d) => {
    if (!d) return '';
    const convertedDate = new Date(moment(d, DATE_FORMAT)).toLocaleString('ru-RU', { month: 'long', year: 'numeric' });
    return convertedDate[0].toUpperCase() + convertedDate.slice(1, -2);
  };

  return (
    <div className={styles.customHeader}>
      <span className={styles.headerText}>
        {convertDate(date)}
      </span>
      <div className={styles.headerArrows}>
        <div className={classNames(styles.headerArrow, styles.headerArrowLeft)} onClick={decreaseMonth}>
          <img src={arrowRightViolet} alt="<" />
        </div>
        <div className={styles.headerArrow} onClick={increaseMonth}>
          <img src={arrowRightViolet} alt=">" />
        </div>
      </div>
    </div>
  );
}

export default MyDateInput;
