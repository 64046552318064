import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './css/index.module.scss';
import MyInput from '../MyInput';
import ArrowUpIcon from '../../assets/icons/arrowUp.svg';
import useComponentVisible from '../../hooks/UseComponentVisible';

function Dropdown({
  text, component, height = 600, setSetter, inputProps, onClick, passComponentProps,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick(!isComponentVisible);
    }

    setIsComponentVisible(!isComponentVisible);
  };

  useEffect(() => {
    // very rare functionality
    if (typeof setSetter === 'function') {
      setSetter(() => setIsComponentVisible);
    }
  }, [setSetter]);

  return (
    <div
      className={styles.wrapper}
      ref={ref}
    >
      <div onClick={handleClick}>
        <MyInput
          placeholder={text}
          withLeftIcon={false}
          withCloseIcon={false}
          className={styles.input}
          wrapperClassName={styles.inputWrapper}
          floatingComponent={(
            <div className={classNames(styles.icon, { [styles.iconOpen]: isComponentVisible })}>
              <img alt="^" src={ArrowUpIcon} />
            </div>
          )}
          {...inputProps}
          error={isComponentVisible ? '' : inputProps?.error}
        />
      </div>

      <div
        style={{ height }}
        className={classNames(styles.component, { [styles.componentOpen]: isComponentVisible })}
      >
        {passComponentProps ? component({ isComponentVisible, setIsComponentVisible }) : component}
      </div>
    </div>
  );
}

export default Dropdown;
