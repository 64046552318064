import React from 'react';
import styles from './css/index.module.scss';

function EmptyWidth({ width }) {
  return (
    <div style={{ width: `${width}px` }} />
  );
}

export default EmptyWidth;
